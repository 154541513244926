import { Brand } from '../app/brand/brand';

export const environment = {
  envName: 'sandbox',
  production: false,
  debugMode: false,
  demo: false,
  apiUrl: 'https://services.sandbox-liberty.com.au/api/onboarding-api/',
  appTitle: 'Liberty - Online Account Activation',
  resendShowAfterSeconds: 60,
  heapAnalytics: {
    include: false,
    heapId: 2677470127,
  },
  hotjar: {
    include: false,
    hotjarId: 486107,
  },
  sentry: {
    dsn: 'https://cf52f67361aa496db4682ed907e4a868@sentry.io/1771606'
  },
  applicationInsights: {
    connectionString: 'InstrumentationKey=ac4eda0e-33cd-42db-a89a-7d3a584f332b;IngestionEndpoint=https://australiasoutheast-0.in.applicationinsights.azure.com/;LiveEndpoint=https://australiasoutheast.livediagnostics.monitor.azure.com/;ApplicationId=0311c02e-a617-4c43-8d2d-5e6ac42f7cea',
  },
  overrideBrand: null as Brand
};
