import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LogErrorInterceptor } from './log-error-interceptor';
import { getSentryErrorInterceptor } from './sentry-error-interceptor';
import { SentryService } from '../shared';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: LogErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useFactory: getSentryErrorInterceptor, multi: true, deps: [SentryService] },
];
