import { Component } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApplicationInsightsService, HeapService, HotjarService } from './shared/index';

import { environment } from '../environments/environment';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.less' ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  public constructor(
    private titleService: Title,
    private heapService: HeapService,
    private applicationsInsightService: ApplicationInsightsService,
    private hotjarService: HotjarService) {

    this.titleService.setTitle(environment.appTitle);

    // load analytics tools
    this.heapService.setHeapConfig();
    this.hotjarService.setHotJarConfig();
    this.applicationsInsightService.load();
  }
}
