import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsightsService } from '../shared';

@Injectable()
export class AppInsightsErrorHandler implements ErrorHandler {
  constructor(private appInsights: ApplicationInsightsService) {
  }

  handleError(error: any): void {
    this.appInsights.logException(error.originalError || error);
  }
}