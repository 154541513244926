import { trigger, state, animate, transition, style } from '@angular/animations';

export const fadeInOut =
  trigger('fadeInOut', [
    // conditions
    state('0', style({ opacity: 0, display: 'none' })),
    state('1', style({ opacity: 1, display: 'block' })),

    // transitions
    transition('0 => 1', animate('0.5s')),
    transition('1 => 0', animate('0.3s')),
  ]);
