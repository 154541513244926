import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[cut-stop-propagation]'
})

export class CutStopPropagation {
  @HostListener('cut', ['$event'])
  onCut(event: any) {
    event.preventDefault();
  }
}
