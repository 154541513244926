import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[copy-stop-propagation]'
})

export class CopyStopPropagation {
  @HostListener('copy', ['$event'])
  onCopy(event: any) {
    event.preventDefault();
  }
}
